@import "./baseSpotifyTheme.css";

button:not(.tab-toggle) {
  background-color: var(--button-background);
  border: solid var(--button-shadow);
  border-top-color: transparent;
  border-right-color: transparent;
  border-width: 0 0 3px 2px;
  margin: 10px;
  padding: var(--section-radius) calc(var(--section-radius) * 2);
  border-radius: calc(var(--section-radius) * 1.5);
  transition-timing-function: linear;
  transition-duration: 150ms;
  height: min-content;
  width: fit-content;
  flex-grow: 0;
  flex-shrink: 0;
}

button:not(.tab-toggle):hover {
  transition-duration: 150ms;
  transition-timing-function: linear;

  background-color: var(--button-highlight);
}
button:not(.tab-toggle):active {
  transition-duration: 150ms;
  transition-timing-function: linear;
  background-color: var(--button-shadow);
}

button.primary {
  border-color: var(--primary-shade);
  background-color: var(--primary);
  color: var(--primary-darker);
}

button.primary:hover {
  background-color: var(--primary-highlight);
  color: var(--primary-contrast);
}

button.primary:active {
  background-color: var(--primary-shade);
}

button.secondary {
  border-color: var(--secondary-shade);
  background-color: var(--secondary);
  color: var(--secondary-darker);
}

button.secondary:hover {
  background-color: var(--secondary-highlight);
  color: var(--secondary-contrast);
}

button.secondary:active {
  background-color: var(--secondary-shade);
}

button.ternary {
  border-color: var(--ternary-shade);
  background-color: var(--ternary);
  color: var(--ternary-darker);
}

button.ternary:hover {
  background-color: var(--ternary-highlight);
  color: var(--ternary-contrast);
}

button.ternary:active {
  background-color: var(--ternary-shade);
}

p.listable-item {
  background-color: var(--accent);
  border: solid var(--accent-shadow);
  color: var(--accent-darker);
  border-top-color: transparent;
  border-right-color: transparent;
  margin: 4px;
  padding: var(--section-radius) calc(var(--section-radius) * 2);
  border-radius: calc(var(--section-radius) * 1.5);
  transition-timing-function: linear;
  height: min-content;
  width: fit-content;
  flex-grow: 0;
  flex-shrink: 0;
}

button.toggle-true,
button.toggle-false {
  translate: 0 0px;
}

button.toggle-true {
  background-color: var(--background-toggle-active);
}

button.toggle-true:hover {
  background-color: var(--highlight-toggle-active);
}

button.toggle-true:active,
button.toggle-false:active {
  background-color: var(--background-toggle-active);
}

button.toggle-false {
  background-color: var(--background-toggle-inactive);
}

button.toggle-false:hover {
  background-color: var(--highlight-toggle-inactive);
}

/* .search-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
  flex-shrink: 1;
  align-content: center;
  justify-content: stretch;
  align-items: center;
  justify-items: stretch;
}

.search-bar input {
  padding: 0.5ex 1ex;
  border: none;
  outline: none;
  border-radius: calc(var(--section-radius) / 2);
}

.search-bar.mobile input {
  flex-grow: 1;
  flex-shrink: 1;
}


.search-bar.desktop {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 1;
  align-content: center;
  justify-content: stretch;
  align-items: center;
  justify-items: stretch;
  margin: 0.5em 0 0;
  min-width: 25vw;
  height: min-content;
}

.search-bar.desktop input {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 25ex;
  max-width: 40%;
} */
/* input {
  border: none;
  margin: 0.5rem;
  padding: 1ex 1ex;
  border-radius: 4px;
  transition-duration: 100ms;
  color: var(--text-inactive);
  box-shadow: var(--button-shadow-none);
  outline: none;
}

input:active,
input:focus,
input:focus-visible {
  color: var(--text-main);
  background-color: var(--background-input-active);
  outline-width: 0;
}

.search-bar {
  display: grid;
  grid-template-areas:
    "a a a a b b"
    "c c d d e e";
  align-self: center;
  justify-self: center;
} */

ul.attributes {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  text-align: center;
}

.listed-attribute {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 4pt;
  padding: 4px;
  justify-items: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: var(--section-radius);
  font-size: 10pt;
}

.listed-attribute.genre {
  background-color: var(--secondary-button-background);
}

.listed-attribute.track {
  background-color: var(--primary-button-background);
}
.listed-attribute.artist {
  background-color: var(--ternary-button-highlight);
}

.listed-attribute button.remove {
  text-transform: lowercase;
  font-size: 14pt;
  font-weight: 900;
  padding: 0;
  margin: 0 4px;
  background-color: transparent;
  flex-grow: 1;
}

.listed-attribute p {
  margin: 0px 4px;
  padding: 0;
  text-transform: capitalize;
}

.selectable-attribute {
  text-transform: capitalize;
  color: var(--text-main);
}
