@import "./baseSpotifyTheme.css";
@import "./spotifyPage.css";

.section.search-bar {
  display: grid;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 1;
}

.section.search-bar.mobile {
  grid-template-areas: "a" "b" "c" "d" "e";
  justify-self: stretch;
  align-self: stretch;
  grid-template-columns: 1fr;
}

.section.search-bar.desktop {
  grid-template-areas: "a a" "b c" "d d" "e e";
  grid-template-columns: 0.8fr 0.2fr;
  grid-template-rows: min-content min-content;
  padding-top: 1ch;
  flex-grow: 0;
  flex-shrink: 0;
  height: min-content;
  justify-self: start;
  align-self: stretch;
}

.section.search-bar > input {
  grid-area: b;
  height: fit-content;
  outline: none;
  border: none;
  justify-self: stretch;
  padding: var(--section-radius) calc(var(--section-radius) * 2);
  border-radius: calc(var(--section-radius) * 2);
  color: var(--text-inactive);
}

.section.search-bar > input:focus-within {
  color: var(--main-text);
}

.section.search-bar > *:first-child {
  text-align: left;
  justify-self: left;
  width: 100%;
  grid-area: a;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.section.search-bar > *:first-child > *:first-child {
}

.section.search-bar.mobile > h2 {
  justify-self: auto;
}

.section.search-bar.mobile > input {
  margin: 1ch 0 0.5ch;
}

.section.search-bar > button {
  width: min-content;
  margin: 0 1ch;
  grid-area: c;
}

.section.search-bar.mobile > button {
  width: 100%;
  margin: 1ch 0;
}

.section.search-bar > p {
  flex-shrink: 1;
  flex-grow: 1;
  background-color: aquamarine;
  grid-area: d;
  transition-duration: 250ms;
  overflow: hidden;
  height: min-content;
}

.section.search-bar > p.message {
  height: fit-content;
}

.section.search-bar > p.no-message {
  height: 0px;
}

.section.section.search-bar > div.inline-result-box {
  grid-area: e;
}

.section.search-result {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-self: stretch;
  justify-self: stretch;
  align-content: stretch;
  justify-content: stretch;
  justify-items: stretch;
  align-items: stretch;
}

.section.search-result img {
  max-width: 50px;
  max-height: 50px;
  border-radius: var(--section-radius);
  object-fit: cover;
}

.result-item {
  margin: 1vmin;
  border-radius: var(--section-radius);
  display: flex;
  flex-direction: row;
  background: linear-gradient();
  align-content: center;
  align-items: center;
}

.result-item img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: calc(var(--section-radius) * 2);
  margin: 1em;
}
.recommendation-result {
  display: grid;
}

.recommendation-result .result-card {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: calc(var(--section-radius) * 2);
  box-shadow: var(--content-shadow);
  background-color: var(--background-white);
  border-radius: calc(var(--section-radius) * 2);
  padding: calc(var(--section-radius) * 2);
}

.result-card .card-section {
  display: grid;
  grid-template-areas: "a b" "a c";
  justify-content: start;
  justify-items: start;
  align-items: start;
  align-content: start;
  text-align: left;
  margin: 3px;
}

.search-result table {
  background-color: var(--background-body);
}
.search-result table > * {
  background-color: white;
}

.result-card .card-section .id-tag {
  grid-area: c;
  align-self: flex-start;
  margin: 0.25em;
}

.result-card .card-section h3 {
  grid-area: b;
  height: min-content;
  margin: 0;
  padding: 0;
}

.result-card .card-section img {
  grid-area: a;
  margin: auto 0.5em;
}

.result-card .card-section h4 {
  height: min-content;
  align-self: flex-start;
  align-items: start;
  grid-area: a;
  margin: 0.25em 0.5em 0.25em 0.1em;
}

.result-card .card-section div {
  grid-area: c;
  align-self: flex-start;
  height: min-content;
  margin: 0.2ex calc(var(--section-radius) / 2);
}

.name-id {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: start;
  align-items: center;
  justify-items: start;
  justify-content: start;
  width: fit-content;
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0;
  padding: 0;
}

.name-id .id-tag,
.name-id p {
  margin: 0.2ex;
  flex-grow: 0;
  flex-shrink: 0;
}
.name-id p {
  width: fit-content;
}

.desktop .result.track {
  display: grid;
  grid-template-columns: 80px 0.2fr min-content min-content;
}

.name-and-tag {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.name-and-tag > * {
  margin: 0;
}

.inline-result-box {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  align-items: stretch;
  justify-content: stretch;
  justify-items: stretch;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: stretch;
  justify-self: stretch;
}

.inline-results {
  display: grid;
  grid-template-areas: "a b b" "a c c";
  align-content: center;
  align-items: center;
  justify-content: left;
  justify-self: stretch;
  align-self: stretch;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 5px 0ex;
  margin: 0;
  border: solid 0 var(--background-body-shade);
  border-top-width: calc(var(--section-radius) / 2);
  border-left-width: calc(var(--section-radius) * 2);
  background-color: color-mix(
    in srgb,
    var(--background-white),
    var(--background-section)
  );
  transition-duration: 80ms;
}

.inline-results:first-child {
  border-top-left-radius: var(--section-radius);
  border-top-width: calc(var(--section-radius) / 2);
}

.inline-results:last-child {
  border-bottom-width: calc(var(--section-radius) / 2);
  border-bottom-left-radius: var(--section-radius);
}

.inline-results:hover {
  border-left: solid calc(var(--section-radius) * 2) var(--primary);
  background-color: var(--background-white);
  transition-duration: 80ms;
}

.inline-results:active {
  transition-duration: 150ms;
  background-color: var(--primary);
}

.inline-results > img {
  grid-area: a;
  height: 50px;
  width: 50px;
  border-radius: var(--section-radius);
  margin: 0 10px 0 6px;
}

.inline-results > p {
  margin: 0 1ex;
  grid-area: c;
  font-weight: 300;
}

.inline-results > p:first-of-type {
  grid-area: b;
  font-weight: 500;
}
