@import url("https://fonts.googleapis.com/css2?family=Geologica:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  font-family: "Geologica", sans-serif;
  --main-font: "Geologica", sans-serif;
  --accent-font: serif;

  --section-radius: 4px;

  --electric: #cdf500;
  --limeade: #2cf50c;
  --limeade-dark: #2bf50c80;
  --dolly: #f518b1;
  --midnight: #2918f5;
  --sunny: #f5d10c;

  --background-body: #c9c8c6;
  --background-body-bright: #dfddd7;
  --background-body-shade: #b1ada7;

  --background-body-darker: #807a73;
  --background-body-text: #504a44;
  --background-body-darkest: #36342f;
  --background-section: #e9e6e2;
  --background-white: #f8f7f5;
  --background-input-inactive: #e0e0e0;
  --text-inactive: rgba(0, 0, 0, 0.5);
  --text-main: #000;
  --text-header: #6e6457;
  --text-main-contrast: #fff;
  --text-secondary: #555;
  --shadow: #092936c0;

  --button-background: var(--background-body);
  --button-contrast: var(--background-body-darkest);
  --button-shadow: var(--background-body-darker);
  --button-highlight: var(--background-body);

  --primary: #74cebc;
  --primary-shade: #559e90;
  --primary-darker: #000c;
  --primary-highlight: #a9e9dc;
  --primary-contrast: #000;

  --secondary: #d99ca1;
  --secondary-shade: #a87176;
  --secondary-darker: #000c;
  --secondary-highlight: #ecb5b9;
  --secondary-contrast: #000;

  --ternary: #9ed1e7;
  --ternary-shade: #5a889c;
  --ternary-darker: #000c;
  --ternary-highlight: #bbe0f0;
  --ternary-contrast: #000;

  --accent: #ecad83;
  --accent-shade: #ac734e;
  --accent-darker: #000c;
  --accent-highlight: #f5bd98;
  --accent-contrast: #000;

  --background-toggle-active: #d18bff;
  --shadow-toggle-active: #a250d8;
  --highlight-toggle-active: #e4bcff;
  --text-button-toggle-active: #35134d;

  --background-toggle-inactive: #bdaaca;
  --shadow-toggle-inactive: #6a5975;
  --highlight-toggle-inactive: #d9cde0;
  --text-button-toggle-inactive: #6a5975;

  --background-button-secondary: #ffa774;
  --shadow-button-secondary: #f16e22;
  --highlight-button-secondary: #ffa774;
  --text-button-secondary: #642d0d;

  --background-button-ternary: #cf49bd;
  --shadow-button-ternary: #86296a;
  --highlight-button-ternary: #ff6ecf;
  --text-button-ternary: #3d1030;

  --content-shadow: 0px 4px 4px 0px #4446;
  --content-shadow-click: 0px 1px 4px 0px #4446;

  --button-shadow-none: inset 0 0 0 0 #444, inset 0 0 0 0 #4446;
  --button-shadow-click: inset 0px 2px 4px -2px #444,
    inset 0px 2px 6px -2px #4444;

  --box-shadow-highlight: inset 0 0 0 100px #fff5;
}

body {
  background-attachment: fixed;
  background-color: var(--background-body);
  color: var(--text-main);
}
