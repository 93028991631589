@import "./baseSpotifyTheme.css";
@import "./spotifyPage.css";

.section.track-information {
  position: relative;
  display: flow-root;
  padding: 0;
  border-radius: var(--section-radius);
  overflow: hidden;
  flex-grow: 0;
  flex-shrink: 0;
}

.track-information-background {
  position: relative;
  height: 100%;
  width: 100%;
  background-repeat: none;
  background-size: cover;
  padding: 0;
  margin: 0;
  background-position: 50%;
  border-radius: var(--section-radius);
  filter: saturate(0.8) brightness(0.6) blur(50px);
  overflow: hidden;
}

.track-info {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 3;
  top: -100%;
  padding: 3vmin;
  border-radius: var(--section-radius);
  align-content: space-evenly;
  justify-content: space-evenly;
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
}

.track-data.main-data {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  color: #fff;
}

.track-data.main-data .title-artists {
  flex-grow: 2;
  flex-shrink: 1;
  align-self: stretch;
  justify-self: stretch;
}

.track-data.main-data .title-artists > * {
  max-width: 50ex;
  justify-self: left;
  overflow-wrap: normal;
}

.track-data.main-data .main-meta {
  flex-grow: 1;
  flex-shrink: 1;
}

.album-image-frame {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  margin: 1vmin 3vmin;
  align-content: center;
  justify-content: center;
  justify-self: flex-start;
}

.album-image {
  text-align: center;
  align-self: center;
  max-width: 200px;
  border-radius: var(--section-radius);
}

.track-data.detail-data {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  flex-grow: 1;
  background-color: var(--background-section);
  border-radius: var(--section-radius);
  padding: 0.5em;
}

.track-data.detail-data *:not(h2) {
  margin-block: 0.7ch;
}

.track-data.detail-data h2 {
  margin-inline-start: 1em;
  margin-inline-end: auto;
  margin-block: 1em 1em;
}
/*
.track-data.detail-data li {
  margin: 1em 1em auto 1em;
} */

.quick-tracklist-labels {
  background-color: var(--text-main);
}
