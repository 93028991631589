@import "./baseSpotifyTheme.css";
@import "./spotifyPage.css";

.section.artist-information {
  position: relative;
  display: flow-root;
  padding: 0;
  border-radius: var(--section-radius);
  overflow: hidden;
  flex-grow: 1;
  flex-shrink: 1;
}

.track-artist-background {
  position: relative;
  height: 100%;
  width: 100%;
  background-repeat: none;
  background-size: cover;
  padding: 0;
  margin: 0;
  background-position: 50%;
  border-radius: var(--section-radius);
  filter: saturate(0.8) brightness(0.6) blur(50px);
  overflow: hidden;
}

.artist-info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  z-index: 3;
  top: -100%;
  padding: 3vmin;
  border-radius: var(--section-radius);
  flex-grow: 0;
  flex-shrink: 0;
  align-content: center;
  justify-content: center;
}

.artist-image-frame {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 1vmin 3vmin;
  align-content: center;
  justify-content: center;
}

.artist-image {
  text-align: center;
  align-self: center;
  border-radius: var(--section-radius);
}

.artist-data.detail-data {
  display: flexbox;
  flex-direction: row;
  padding: 2vmin;
  flex-grow: 1;

  background-color: var(--background-section);
  border-radius: var(--section-radius);
}

.section.artist-albums {
  flex-direction: column;
  align-items: stretch;
  padding: 2vmin;
}

.section.artist-albums h2 {
  font-weight: 600;
  padding-inline: 2em;
}

.album-card-partition {
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  align-content: stretch;
  padding: 2ex;
  gap: 1ex;
  justify-self: stretch;
  align-self: stretch;
  flex-grow: 1;
  flex-shrink: 1;
}

.album-card {
  box-shadow: var(--content-shadow);
  display: grid;
  grid-template-areas: "a b" "a c" "a d";
  padding: 1ex;
  flex-grow: 1;
  flex-shrink: 4;
  column-gap: 1ex;
  background-color: var(--background-white);
  border-radius: calc(var(--section-radius) * 2);
  justify-content: left;
  max-height: min-content;
}

.album-card img {
  border-radius: var(--section-radius);
}

.album-card > p {
  font-size: 11pt;
  margin: 0;
}

.album-card > p:first-of-type {
  font-size: 12pt;
  text-overflow: ellipsis;
}
