@import "./baseSpotifyTheme.css";
@import "./spotifyPage.css";

/* Shape on the radar chart */
.shape {
  fill-opacity: 80%;
  transition: 300ms;
  stroke: var(--text-main);
  stroke-width: 1px;
  stroke-opacity: 60%;
}

/* The scale markers on the radar chart */
.scale {
  stroke: var(--text-main);
  fill: var(--text-main);
  fill-opacity: 0%;
  stroke-width: 1px;
  stroke-opacity: 40%;
}

/* The axis of the radar chart */
.axis {
  stroke: var(--text-main);
  stroke-width: 2px;
  stroke-opacity: 40%;
}

/* The labels on the radar chart */
.caption {
  fill: var(--text-main);
  font-family: "Geologica";
  font-size: 11pt;
}

.radar-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  align-self: center;
  justify-self: center;
  flex-grow: 1;
  flex-wrap: wrap;
  margin: 1em;
  padding: 0;
  background-color: var(--background-white);
  width: fit-content;
  border-radius: calc(var(--section-radius) * 2);
}

.radar-chart.mobile {
  margin: 1em 0;
}

.radar-chart .chart-meta {
  padding: 1em;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 1;
  height: min-content;
}

.radar-chart h3 {
  text-align: center;
  margin: 0;
  padding: 1ch 0 0 0;
}

.radar-chart .chart-meta > p {
  font-size: 10pt;
  color: #000a;
  flex-grow: 1;
  flex-shrink: 1;
  padding: calc(var(--section-radius) / 2) var(--section-radius);
  border-radius: var(--section-radius);
  margin: 0 var(--section-radius);
}

.section.track-features {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  align-content: stretch;
  justify-content: stretch;
  align-items: stretch;
  justify-items: stretch;
}

.quick-tracklist-label {
  padding: calc(var(--section-radius) / 2) calc(var(--section-radius) * 2);
  border-radius: calc(var(--section-radius) * 2);
  color: var(--background-white);
  font-weight: 500;
  text-align: center;
}
