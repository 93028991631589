@import "./spotifyPage.css";

.content > .grid-chunk {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 1;
  justify-self: stretch;
  align-self: stretch;
}

.section.meta-card {
  justify-self: stretch;
  align-self: stretch;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: stretch;
  align-content: stretch;
  padding-top: 2ch;
  padding-bottom: 2ch;
}

.section.meta-card a {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-self: center;
  align-self: center;
  justify-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
}

.section.meta-card img {
  object-fit: cover;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: var(--section-radius);
}

.desktop .section.meta-card img {
  max-width: 350px;
  max-height: 350px;
}

.mobile .section.meta-card img {
  max-width: 200px;
  max-height: 200px;
}

.section.meta-card p:not(.id-tag) {
  font-size: 11pt;
  text-align: center;
  margin-top: 0.5ch;
  margin-bottom: 0;
  font-weight: 400;
}

.section.meta-card > p:first-of-type:not(.id-tag) {
  font-size: 14pt;
  font-weight: 500;
  color: var(--text-header);
  text-align: center;
  margin-top: 1ch;
  margin-bottom: 0;
}

.id-tag {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: right;
  justify-items: right;
  align-self: center;
  font-size: 10pt;
  width: min-content;
  height: min-content;
  color: var(--accent-darker);
  padding: 0;
  padding-right: 0;
  border-radius: var(--section-radius);
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  text-align: right;
}

.id-tag > p {
  background-color: color-mix(in srgb, var(--accent), #0000 60%);
  margin: 0;
  padding: 0.5ch;

  height: 16px;
  justify-items: center;
  align-items: center;
  padding-top: 1px;
  padding-bottom: 3px;
  flex-grow: 1;
  flex-shrink: 1;
  border-top-left-radius: var(--section-radius);
  border-bottom-left-radius: var(--section-radius);
}

.id-tag > div {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-self: flex-end;
  margin: 0;
  padding: 0;
  flex-grow: 0;
  flex-shrink: 0;
}
.id-tag > div > * {
  flex-grow: 0;
  flex-shrink: 0;
  height: 16px;
  width: 16px;
  justify-self: flex-end;
  align-self: center;
  padding: 0;
  margin: 0;
  padding: 2px;
  transition-duration: 100ms;
  background-color: var(--accent);
  fill: var(-accent-contrast);
}

.id-tag > div > *:first-child {
}

.id-tag > div > *:last-child {
  border-top-right-radius: var(--section-radius);
  border-bottom-right-radius: var(--section-radius);
}

.id-tag > div > *:hover {
  background-color: var(--accent-highlight);
  opacity: 80%;
}

.id-tag > div > *:active {
  background-color: var(--accent-shade);
  opacity: 100%;
}
