@import "./baseSpotifyTheme.css";
@import "./interaction.css";

.spotify-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 0;
  margin: 0;
  align-content: stretch;
  justify-content: stretch;
  font-family: var(--main-font);
}

.spotify-page * {
  font-family: var(--main-font);
}

.content {
  margin: 0 0 1em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: stretch;
  justify-self: stretch;
}

.content.mobile {
  padding: 0 0.5em;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: stretch;
  align-content: stretch;
  justify-items: stretch;
  align-items: stretch;
}

.content.desktop {
  padding: 0 1em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: start;
  margin-bottom: 50px;
}

.section.desktop:last-child {
  justify-self: stretch;
}

.page-title {
  text-transform: uppercase;
  padding: 0 0 0 0.5em;
  align-self: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  color: var(--background-body-text);
  font-weight: 100;
  font-size: 24pt;
  justify-self: start;
}

.page-title.desktop {
  margin: 1em 0 0.5em;
  padding: 0 0 0 1em;
  text-transform: uppercase;
  letter-spacing: 0.2ch;
}

.page-title.mobile {
  text-transform: uppercase;
  letter-spacing: 0.15ex;
  font-size: 24pt;
  font-weight: 100;
}

.section {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  background-color: var(--background-section);
  border-radius: var(--section-radius);
  box-shadow: var(--content-shadow);
  color: (--text-main);
  justify-self: stretch;
  flex-grow: 1;
  flex-shrink: 1;
}

.section h1,
.section h2,
.section h3 {
  color: var(--text-header);
  font-weight: 200;
}

.section h2 {
  font-size: 20pt;
}

.section.desktop {
  padding: 1ex 3ex 2ex;
  align-self: self-start;
  border-left: solid 1em var(--background-body-darker);
  margin: 1ex;
}

.section.mobile {
  padding: 1ex 3ex;
  margin: 1ex 2ex;
  align-self: stretch;
}

.section.mobile:last-child {
  margin: 1ex 2ex 4ex;
}

.section.informational p,
.section.informational li {
  font-weight: 300;
}

.section.informational li {
  padding: 0.5ex 2em 0.5ex 0;
}

.main-menu {
  padding: 0;
  margin: 0;
  border-radius: 0;
  background-color: var(--background-body-darker);
  display: flex;
}

.main-menu > button {
  border-radius: 0;
  margin: 0;
  padding: 0;
}

.main-menu.mobile {
  flex-direction: column;
  flex-wrap: nowrap;
  background-color: var(--background-body-bright);
  box-shadow: 0 0 8px 0px var(--shadow);
}

.main-menu.desktop {
  box-shadow: inset 0 -8px 8px -8px var(--shadow);
}

.tab-toggle-bar {
  display: flex;
  border-radius: 0;
  height: auto;
  overflow: hidden;
  margin: 0;
}

.tab-toggle-bar.mobile {
  background-color: var(--background-body-darker);
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: stretch;
  align-content: stretch;
  flex-grow: 1;
  flex-shrink: 1;
}

.tab-toggle-bar.mobile.hidden,
.tab-toggle-bar.desktop.hidden {
  height: 0px;
}

.tab-toggle-bar.mobile.visible,
.tab-toggle-bar.desktop.visible {
  flex-grow: 1;
  flex-shrink: 1;
  height: auto;
}

.tab-toggle {
  font-family: "Geologica", sans-serif;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  justify-items: center;
  outline: none;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: none;
  border-radius: 0;
  border: none;
  margin: 0;
  font-size: 11pt;
  font-weight: 300;
  color: var(--text-main-contrast);
  transition-duration: 150ms;
  box-shadow: none;
  text-align: left;
  padding: 0.25em;
  background-color: transparent;
}

.tab-toggle.mobile {
  padding: 0.5em 0 0.5em 2em;
}
.tab-toggle.desktop {
  padding: calc(0.25em + 0.5ch) 0;
  text-align: center;
}

.tab-toggle.mobile:first-child {
  margin-top: 1em;
}

.tab-toggle.mobile:last-child {
  margin-bottom: 1em;
}

.tab-toggle.inactive {
  box-shadow: none;
}

.tab-toggle.mobile.active {
  font-weight: 800;
  background-color: var(--background-body);
  color: var(--background-body-text);
  border-left: solid 0.5em var(--primary);
}

.tab-toggle.active.desktop {
  box-shadow: inset 0 0 0 100px var(--background-body);
  font-weight: 800;
  color: var(--background-body-text);
  border-top: solid 0.5ch var(--primary);
  padding: calc(0.25em + 0.5ch) 0 0.25em;
}

.tab-icon * {
  flex-grow: 1;
  flex-shrink: 1;
  fill: var(--background-body-bright);
  height: 1.5em;
  margin: 0;
  padding: 0;
}

.tab-toggle.active > .tab-icon * {
  fill: var(--background-body-text);
}

.tab-icon {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}
