@import "baseSpotifyTheme.css";
@import "interaction.css";
@import "spotifyPage.css";

.section.desktop.user-shape {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: space-around;
}

.top-items-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: start;
  justify-content: start;
}

.section.user-artists {
  display: flex;
  flex-direction: column;
}

.section.user-artists.desktop li {
  font-size: 12pt;
  font-weight: 500;
  padding: 0.25ex;
}

.section.user-artists.desktop .listable-item {
  font-size: 11pt;
  font-weight: 500;
}

.section.desktop-user .section.user-shape.mobile {
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
}

.top-item.mobile {
  display: grid;
  grid-template-areas: "a b" "c c";
  grid-template-columns: 8ex auto;
  grid-template-rows: 0.5fr 0.5fr;
  margin: 0.5ex;
  padding: 0.5ex 2ex;
  background-color: var(--background-white);
  border-radius: var(--section-radius);
  box-shadow: var(--content-shadow);
  text-align: left;
  flex-grow: 1;
  flex-shrink: 1;
}

.top-item.desktop {
  display: grid;
  grid-template-areas: "a b" "a c";
  grid-template-columns: 8ex auto;
  grid-template-rows: 0.5fr 0.5fr;
  flex-wrap: nowrap;
  margin: 0.5ex;
  padding: 0.5ex 2ex;
  background-color: var(--background-white);
  border-radius: var(--section-radius);
  box-shadow: var(--content-shadow);
  flex-grow: 1;
  flex-shrink: 1;
  min-width: fit-content;
}

.top-item > img {
  grid-area: a;
  border-radius: calc(var(--section-radius));
  flex-grow: 0;
  height: 6ex;
  flex-shrink: 1;
  margin: 0.5ex 1ex 0.5ex 0;
  align-self: center;
  padding: 0;
}

.desktop .top-item > .id-tag {
  margin: 0;
  grid-area: c;
  flex-grow: 0;
  flex-shrink: 0;
  height: min-content;
}

.mobile .top-item > .id-tag {
  margin: 0;
  grid-area: c;
  flex-grow: 0;
  flex-shrink: 0;
  height: min-content;
  align-self: center;
  justify-self: center;
}

.top-item > p {
  grid-area: b;
  text-align: left;
}

.top-tracks.hidden {
  width: 0;
  height: 0;
  overflow: hidden;
}

.top-tracks.desktop {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-self: end;
  align-content: center;
  justify-content: space-evenly;
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 50%;
  overflow: auto;
}

.currently-playing {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.currently-playing img {
  max-width: 200px;
  max-height: 200px;
  object-fit: cover;
  border-radius: var(--section-radius);
  margin: 1ex;
}

.currently-playing > .details {
  padding: 1ex;
  flex-grow: 1;
  flex-shrink: 1;
}

.currently-playing > .details p:first-child {
  font-size: larger;
  font-weight: 500;
}

.currently-playing > .details p:last-child {
  font-style: italic;
  opacity: 60%;
}

.log-out {
  width: fit-content;
  height: fit-content;
  font-family: 16pt;
  font-weight: 900;
  text-transform: uppercase;
  box-shadow: var(--content-shadow);
  border: solid 0.5ex #0008;
}

.section.desktop .profile-img {
  max-width: 200px;
  max-height: 200px;
  object-fit: cover;
}

.section.mobile .profile-img {
  max-width: 120px;
  max-height: 120px;
  object-fit: cover;
  border-radius: var(--section-radius);
  box-shadow: var(--content-shadow);
}

.user-artists.mobile > .user-artists-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-items: center;
  justify-content: center;
}

.user-artists.desktop > .user-artists-content {
  display: flex;

  flex-direction: row;
  justify-content: end;
}

.user-artists.mobile .list-part {
  display: flex;
  flex-direction: column;
}

.user-artists.mobile .genres-list {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: stretch;
  align-content: stretch;
}

.user-artists.desktop .genres-list {
  display: grid;
  grid-template-columns: repeat(4, auto);
  justify-content: stretch;
  align-content: stretch;
}
.user-artists.mobile .genres-list > p {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-grow: 1;
  flex-wrap: 1;
  margin: calc(var(--section-radius) / 2);
  padding: var(--section-radius);
  border-radius: var(--section-radius);
  background-color: var(--background-white);
}

.user-artists.desktop .genres-list > p {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-grow: 1;
  flex-wrap: 1;
  margin: calc(var(--section-radius) / 2);
  padding: calc(var(--section-radius) * 2);
  border-radius: var(--section-radius);
  background-color: var(--background-white);
}

.user-artists > .user-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
}

.user-artists > .user-list > .item {
  flex-grow: 1;
  flex-shrink: 1;
  justify-self: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

.user-list > .item > *:first-child {
  text-align: center;
}
